<template lang="pug">
  div.account_wrap
    AccountItem(:account="this.account")
</template>

<script>
import { mapState } from 'vuex'
import AccountItem from '@/components/AccountItem'
export default {
  name: 'AccountView',
  metaInfo: {
    title: 'アカウント画面',
  },
  components: {
    AccountItem,
  },
  computed: {
    ...mapState('authModule', ['account']),
  },
}
</script>
